
































































import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'ProfileDefaultPage',

  computed: {
    ...mapGetters(['getThemeMode']),
    ...mapGetters({ user: 'auth/getUser' })
  }
});
